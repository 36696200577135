.btn {
    @apply font-bold py-2 px-4 rounded dark:hover:text-white;
}

.btn-emerald {
    @apply text-emerald-600 dark:text-emerald-400;
}

.btn-emerald.active{
    @apply bg-emerald-600 text-white;
}

.btn-emerald:hover:not(.active) {
    @apply bg-emerald-100 dark:bg-emerald-900;
}

.btn-sky {
    @apply text-sky-600 dark:text-sky-400;
}

.btn-sky.active{
    @apply bg-sky-600 text-white;
}

.btn-sky:hover:not(.active) {
    @apply bg-sky-100 dark:bg-sky-900;
}

